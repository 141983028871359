import React from 'react';
import { Box, Button } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ModalComponent from '../utils/ModalComponent';

const emailValidationSchema = Yup.object({
  recipient: Yup.string().email('Invalid email address').required('Recipient email is required'),
  subject: Yup.string().required('Subject is required'),
  body: Yup.string().required('Body is required'),
});

const EmailVerification = ({ emailVerification, setEmailVerification }) => {
  const handleEmailData = async (emailData) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/send-email", {
        method: "POST",
        body: JSON.stringify(emailData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      if (response.ok) {
        alert("Email sent successfully!");
      } else {
        alert("Failed to send email.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Error sending email.");
    }
  };

  const handleClose = () => {
    setEmailVerification(!emailVerification);
  };

  return (
    <div>
      <ModalComponent
        open={emailVerification}
        handleClose={handleClose} // Make sure handleClose is passed correctly
      >
        <Box>
          <Formik
            initialValues={{ recipient: '', subject: '', body: '' }}
            validationSchema={emailValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleEmailData(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              touched,
              errors,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className='email_verification_box'>
                  <label htmlFor="recipient" className='label'>Recipient Email-Id</label>
                  <input
                    type="text"
                    name="recipient"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.recipient}
                    className='input_field'
                  />
                  {touched.recipient && errors.recipient && (
                    <div className="errormessage">{errors.recipient}</div>
                  )}
                </div>

                <div className='email_verification_box'>
                  <label htmlFor="subject" className='label'>Subject</label>
                  <input
                    type="text"
                    name="subject"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subject}
                    className='input_field'
                  />
                  {touched.subject && errors.subject && (
                    <div className="errormessage">{errors.subject}</div>
                  )}
                </div>

                <div className='email_verification_box'>
                  <label htmlFor="body" className='label'>Body</label>
                  <textarea
                    type="text"
                    name="body"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.body}
                    className='input_field_body'
                  />
                  {touched.body && errors.body && (
                    <div className="errormessage">{errors.body}</div>
                  )}
                </div>

                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </ModalComponent>
    </div>
  );
};

export default EmailVerification;
