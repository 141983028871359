import React from "react";
import styled from "styled-components";
import Chatbot from "./Chatbot";
import VideoPlayer from "./VideoPlayer"
import ChatList from "./ChatList"
//import FileUpload from "./FileUpload";
import Header from "./Header";
import Footer from "./Footer";
import { Grid, Paper } from "@mui/material";
import { useParams } from "react-router-dom";

function ChatBox() {
  const { base } = useParams();
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <Header />
        </Grid>
      </Grid>
      <Grid container spacing={0}>       
        <Grid item xs={12} md={12}>
          <Paper>
            <Chatbot />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
  /*
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Header />
        </Grid>
      </Grid>
      {base === 'cra' ? (
        <Grid container spacing={0}>
        {<Grid item xs={12} md={2.8}>
          <ChatList />
        </Grid> 
        }
         <Grid item xs={12} md={9.2}>
           <Paper>
             <Chatbot />
           </Paper>
         </Grid>
       </Grid>
      ) : (
        <Grid container spacing={0}>
         {<Grid item xs={12} md={2.8}>
           <ChatList />
         </Grid> 
         }
          <Grid item xs={12} md={9.2}>
            <Paper>
              <Chatbot />
            </Paper>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
  */
}

const ChatBoxContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  height: 90vh;

  background-color: white;
  margin-left:-4%;
`;

const ChatBoxFullContainer = styled.div`
  // display: flex;
  height: 100vh;
  background-color: white;
`;

export default ChatBox;
