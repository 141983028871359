import React, { createContext, useContext, useState, useEffect } from "react";

const ToggleContext = createContext();

export const useToggle = () => useContext(ToggleContext);

export const ToggleProvider = ({ children }) => {
  // Initialize state based on localStorage or default to true
  const [isVideoSelected, setIsVideoSelected] = useState(
    JSON.parse(localStorage.getItem("isVideoSelected")) ?? true
  );

  // Update localStorage when isVideoSelected changes
  useEffect(() => {
    localStorage.setItem("isVideoSelected", JSON.stringify(isVideoSelected));
  }, [isVideoSelected]);

  return (
    <ToggleContext.Provider value={{ isVideoSelected, setIsVideoSelected }}>
      {children}
    </ToggleContext.Provider>
  );
};
