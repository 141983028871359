import React from 'react';

const PromptCard = ({ text, size, onClick }) => {
  return (
    <div style={cardStyle(size)} onClick={onClick}>
      {text}
    </div>
  );
};

const getCardWidth = (numCards) => {
  switch (numCards) {
    case 1:
      return '97%';
    case 2:
    case 4:
      return '48%';
    default:
      return '32%'; 
  }
};

const cardStyle = (numCards) => ({
  cursor: 'pointer',
  padding: '15px',
  margin: '15px 0',
  backgroundColor: '#f0f0f0',
  borderRadius: '10px',
  textAlign: 'center',
  width: getCardWidth(numCards),
  minHeight: '50px', // Fixed height
  // overflow: 'hidden',
  // textOverflow: 'ellipsis',
  // whiteSpace: 'nowrap',
  // fontSize: "13px",
  fontFamily: 'helvetica-w01-roman, helvetica-w02-roman, helvetica-lt-w10-roman, sans-serif', // Added font family
  fontStyle: 'normal', // Added font style
  fontVariantCaps: 'normal', // Added font variant caps
  fontVariantEastAsian: 'normal', // Added font variant east-asian
  fontVariantLigatures: 'normal', // Added font variant ligatures
  fontVariantNumeric: 'normal', // Added font variant numeric
  fontWeight: '400', // Added font weight
});

export default PromptCard;