import React from "react";
import styled from "styled-components";
import Login from "./Login";
//import VideoPlayer from "./VideoPlayer";
import Header from "./Header";
import Footer from "./Footer";
import { Grid } from "@mui/material";

function LoginHomePage() {
  return (
    <>
      {/* <Header />
      <AppContainer>
        <Grid container spacing={0} sx={{ gap: { md: "0", sx: "0" } }}>
          <Grid item xs={12} md={2.82}>
            <VideoPlayer />
          </Grid>
          <Grid item xs={12} md={9.18} style={{ backgroundColor: "#EEEEEE" }}>
            <Login />
          </Grid>
        </Grid>
      </AppContainer>
      <Footer /> */}

<PageContainer>
      <Grid container>
        <Grid item xs={12}>
          <Header />
        </Grid>
      </Grid>
      <ContentContainer>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Login />
          </Grid>
        </Grid>
      </ContentContainer>
      <Grid container>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </PageContainer>

      {/* <Grid container spacing={0}>
        <Grid item xs={12} md={12}></Grid>
        <Header />
      </Grid>
      <AppContainer>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={3}
            style={{ padding: "2rem", paddingRight: ".5rem" }}
          >
            <VideoPlayer />
          </Grid>
          <Grid item xs={12} md={9} style={{ backgroundColor: "#EEEEEE" }}>
            <Login />
          </Grid>
        </Grid>
      </AppContainer>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
      </Grid> */}
    </>
  );
}

const AppContainer = styled.div`
  display: flex;
  height: 90vh;
  background-color: white;
  /* padding: 1rem; */
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  background-color: #eeeeee;
`;

export default LoginHomePage;
