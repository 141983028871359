import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, TextField, MenuItem } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

const SignupSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First name is required"),
  last_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  userType: Yup.string()
    .oneOf(["teacher", "student"], "Please select a user type")
    .required("User type is required"),
    password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

function Signup() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSignup = async (values) => {
    try {
      const { confirmPassword, ...postData } = values;
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "/signup",
        postData
      );
      const data = await response.data;
      console.log(data);
      if (data.status === "success") {
        alert(data.message);
        navigate("/signin");
      } else if (data.status === "fail") {
        alert(data.message);
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const navigateToLogin = () => {
    navigate("/signin");
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className="signup_style"
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          marginTop: { lg: "10%", md: "2%" },
        }}
      >
        <div style={BoxStyle}>
          <div className="signup_heading">
            <h2>Sign Up</h2>
          </div>

          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              userType: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={SignupSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values) => handleSignup(values)}
          >
            {({
              values,
              handleChange,
              handleBlur,
              isSubmitting,
              isValid,
              dirty,
              touched,
              errors,
            }) => (
              <Form>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      style={InputStyle}
                      name="first_name"
                      type="text"
                      placeholder="First Name"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="error"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      style={InputStyle}
                      name="last_name"
                      type="text"
                      placeholder="Last Name"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="last_name"
                      component="div"
                      className="error"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      style={InputStyle}
                      name="email"
                      type="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      style={InputSelectStyle}
                      name="userType"
                      value={values.userType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.userType && Boolean(errors.userType)}
                      // label="User Type"
                      SelectProps={{
                        displayEmpty: true,
                        renderValue: (value) => {
                          if (!value) {
                            return (
                              <span style={{ textAlign: "left" }}>
                                User Type
                              </span>
                            );
                          }
                          return value;
                        },
                        style: { textAlign: "left" },
                      }}
                    >
                      <MenuItem value="teacher">Teacher</MenuItem>
                      <MenuItem value="student">Student</MenuItem>
                    </TextField>
                    <ErrorMessage
                      name="userType"
                      component="div"
                      className="error"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      style={InputStyle}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityIcon style={{ fontSize: "18px" }} />
                            ) : (
                              <VisibilityOffIcon style={{ fontSize: "18px" }} />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      style={InputStyle}
                      name="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityIcon style={{ fontSize: "18px" }} />
                            ) : (
                              <VisibilityOffIcon style={{ fontSize: "18px" }} />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error"
                    />
                  </Grid>
                </Grid>

                <button
                  type="submit"
                  style={{
                    ...CreateAccountButtonStyle,
                    backgroundColor:
                      !isValid || !dirty || isSubmitting
                        ? "#cccccc"
                        : "#234bd6",
                  }}
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  {isSubmitting ? "Signing up..." : "Sign Up"}
                </button>
              </Form>
            )}
          </Formik>

          <div className="LoginTextStyle">
            Already have an account?{" "}
            <span style={LoginLinkStyle} onClick={navigateToLogin}>
              Sign In
            </span>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

const BoxStyle = {
  backgroundColor: "#EEEEEE",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "10px",
  padding: "20px",
};

const InputStyle = {
  border: "2px solid #234bd6",
  borderRadius: "8px",
};
const InputSelectStyle = {
  border: "2px solid #234bd6",
  borderRadius: "8px",
  // height:"50px"
};

const CreateAccountButtonStyle = {
  width: "100%",
  // height: "40px",
  height: "37px",
  margin: "15px 0",
  border: "2px solid #234bd6",
  borderRadius: "8px",
  fontSize: "20px",
  backgroundColor: "#234bd6",
  color: "white",
  fontWeight: "bold",
};

const LoginLinkStyle = {
  color: "#234bd6",
  cursor: "pointer",
};

export default Signup;
