import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  buttonDisabled: true,
  emailError: "",
  passwordError: "",
  firstNameError: "",
  lastNameError: "",
  emailTouched: false,
  passwordTouched: false,
  firstNameTouched: false,
  lastNameTouched: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
  },
});

export const { setEmail, setPassword, setFirstName, setLastName } =
  userSlice.actions;

export default userSlice.reducer;
