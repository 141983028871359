// src/components/ChatResponse.js
import React from "react";
import { marked } from "marked";

const ChatResponse = ({ text, userType }) => {
  // Create a custom renderer
  const renderer = new marked.Renderer();

  // Override function to render links
  renderer.link = (href, title, text) => {
    return `<a target="_blank" rel="noopener noreferrer" href="${href}" title="${title}">${text}</a>`;
  };

  const markdownToHtml = marked(text, { renderer });

  return (
    <div style={userType === "user" ? containerRight : containerLeft}>
      <div
        style={userType === "user" ? responseStyle : airesponse}
        dangerouslySetInnerHTML={{ __html: markdownToHtml }}
      />
    </div>
    // {/* {text} */}

    // </div>
  );
};

const containerRight = {
  display: "flex",
  justifyContent: "flex-end", // Align user's message to the right
  marginBottom: "10px",
};

const containerLeft = {
  display: "flex",
  justifyContent: "flex-start", // Align AI's response to the left
  marginBottom: "10px",
};

const airesponse = {
  // float: "left",
  padding: "15px 10px",
  margin: "10px",
  borderRadius: "20px 20px 1px 20px",
  background: "#00aaa5",
  color: "#fff",
  maxWidth: "700px",
  width: "fit-content", // Use fit-content to adjust width based on content
  display: "inline-block", // Ensure it behaves like an inline block
  wordBreak: "break-word", // marginTop: "60px",
};

const responseStyle = {
  // width: "100%",
  // margin: '10px 0',
  // padding: '10px',
  // fontSize: "13px",
  lineHeight: "1.5",
  wordBreak: "break-word",
  fontFamily:
    "helvetica-w01-roman, helvetica-w02-roman, helvetica-lt-w10-roman, sans-serif", // Added font family
  fontStyle: "normal", // Added font style
  fontVariantCaps: "normal", // Added font variant caps
  fontVariantEastAsian: "normal", // Added font variant east-asian
  fontVariantLigatures: "normal", // Added font variant ligatures
  fontVariantNumeric: "normal", // Added font variant numeric
  fontWeight: "400", // Added font weight
  // float: "right",
  padding: "15px 10px",
  margin: "10px",
  borderRadius: "20px 20px 1px 20px",
  background: "#F4F4F4",
  color: "#000",
  width: "fit-content", // Use fit-content to adjust width based on content
  display: "inline-block",
};

export default ChatResponse;
