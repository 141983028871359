import React from "react";
import styled from "styled-components";
import Signup from "./Signup";
import VideoPlayer from "./VideoPlayer";
import Header from "./Header";
import Footer from "./Footer";
import { Grid } from "@mui/material";
// <<<<<<< HEAD
function SignupHomePage() {
  return (
    <>
      <PageContainer>
      <Grid container>
        <Grid item xs={12}>
          <Header />
        </Grid>
      </Grid>
      <ContentContainer>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Signup />
          </Grid>
        </Grid>
      </ContentContainer>
      <Grid container>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </PageContainer>
      {/* =======

function SignupHomePage() {
  return (
    // <AppContainer>
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Header />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <VideoPlayer />
        </Grid>
        <Grid item xs={12} md={6}>
          <Signup />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
>>>>>>> development */}
    </>
  );
}

const AppContainer = styled.div`
  display: flex;
  height: 90vh;
  background-color: white;
  /* padding: 1rem; */
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  background-color: #eeeeee;
`;

export default SignupHomePage;
