import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ChatList from "./ChatList";
import { useLocation, useParams } from "react-router-dom";
import { useToggle } from "./ToggleContext";

const VideoPlayer = () => {
  const { base } = useParams();
  const { isVideoSelected } = useToggle();
  const location = useLocation();
  const hideOptions =
    location.pathname === "/signin" || location.pathname === "/signup";
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    setIsLoggedIn(!!token);
  }, []);

  const getVideoSource = () => {
    if (location.pathname === "/signin") {
      return "/signin_video.mp4";
    } else if (location.pathname === "/signin/1") {
      return "/after_signup_video.mp4";
    } else if (location.pathname === "/signup") {
      return "/signup_video.mp4";
    } else if (isLoggedIn) {
      return "/logged_in_video.mp4";
    } else if (base){
      return base + "_welcome_video.mp4";
    } else {
      return "/new_user_video.mp4";
    }
  };

  // Call the function to determine the video source
  const videoSource = getVideoSource();

  return (
    <VideoContainer>
      {!hideOptions && isLoggedIn ? (
        <>
          {isVideoSelected ? (
            <video width="100%" controls className="video_player">
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <ChatList />
          )}
        </>
      ) : (
        <video
          // width="100%"
          // height={530}
          width="100%"
          controls
          className="video_player"
        >
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </VideoContainer>
  );
};

const VideoContainer = styled.div`
  flex: 1;
  background-color: #fff;

  /* margin-left: 35%; */
  /* margin-top: -3rem; */
  // padding: 0.5rem;
`;

export default VideoPlayer;
