import React from "react";
import styled from "styled-components";
// import { Widget } from 'react-chat-widget';

import MainContent from "./MainContent";
import "react-chat-widget/lib/styles.css";

const Chatbot = () => {
  return (
    <>
       <ChatContainer> 
      <MainContent />
      </ChatContainer>
    </>
  );
};

const ChatContainer = styled.div`
  height: 90vh;
  width: 100vh;
`;

export default Chatbot;
