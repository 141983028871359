// // components/AuthContext.js
// import React, { createContext, useState } from 'react';

// // Create the context
// export const AuthContext = createContext();

// // Create a provider component
// export const AuthProvider = ({ children }) => {
//   const [token, setToken] = useState(null);

//   // A function to log in and set the token (you can modify it as per your login logic)
//   const login = (newToken) => {
//     setToken(newToken);
//   };

//   // A function to log out and clear the token
//   const logout = () => {
//     setToken(null);
//   };

//   return (
//     <AuthContext.Provider value={{ token, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };
// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Retrieve token from local storage or API call
    const savedToken = localStorage.getItem('authToken');
    if (savedToken) {
      setToken(savedToken);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};